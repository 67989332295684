<template>
  <div>
    <login-popup @close="goTo('')" isReset="isReset" />
  </div>
</template>

<script>
import LoginPopup from '@/views/auth/LoginPopup.vue'

export default {
  components: {
    LoginPopup
  },
  name: 'Reset Password',
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  }
}
</script>
