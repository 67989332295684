<template>
  <div class="w-100" >
    <div class="container-lg">
      <!--TOP SECTION-->
      <div class="row m-0 justify-content-center">
        <div class="col-12 p-0">
          <PageTitle :title="$route.name" />
          <div class="row m-0 justify-content-center">
            <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
              <!--STATUS SPINNER-->
              <div class="row mx-0 my-3 justify-content-center align-items-center" v-if="groupMembersStatus === 'joining'">
                <div class="col-auto ps-0 text-capitalize">
                  {{groupMembersStatus}} the Group
                </div>
                <div class="col-auto p-0">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <!--END STATUS SPINNER-->
              <span v-else-if="groupMembersStatus === 'error'">There was an error joining the Group</span>
              <span v-else-if="groupMembersStatus === 'ready'">Group Membership Verified</span>
            </div>
          </div>
        </div>
      </div>
      <!--END TOP SECTION-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue'))
  },
  name: 'DonorGroupConfirmation',
  data () {
    return {
      status: null
    }
  },
  async mounted () {
    const theToken = this.$attrs.query
    await this.verifyInvite(theToken)
  },
  methods: {
    ...mapActions(['verifyInvite'])
  },
  computed: {
    ...mapGetters(['groupMembersStatus'])
  }
}
</script>

<style scoped>

</style>
