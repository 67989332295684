<template>
  <div class="w-100" >
    <div class="row m-0 justify-content-center">
      <ProfileInfo />
      <ProfileCardsSection @goToDonations="goToDonations" />
      <ProfileDonationsSection :scrollTo="goToDonationsSection" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    ProfileInfo: defineAsyncComponent(() => import('./views/ProfileInfo.vue')),
    ProfileCardsSection: defineAsyncComponent(() => import('./views/ProfileCardsSection.vue')),
    ProfileDonationsSection: defineAsyncComponent(() => import('./views/ProfileDonationsSection.vue'))
  },
  name: 'Profile',
  data () {
    return {
      goToDonationsSection: false
    }
  },
  methods: {
    goToDonations () {
      this.goToDonationsSection = true
    }
  }
}
</script>
