<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                Donations for Group:<br/><span v-if="group">{{group.donorGroupName}}</span>
              </div>
            </div>
            <div class="row m-0 justify-content-center">
              <div class="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 my-4">
                <Card icon="icon" heading="Back to Groups" @click="toGroups" class="cursor">
                  <IconGroups color="gold" size="size28" />
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 my-3 my-md-5" >
      <div class="col-12" v-if="groupTransactions && groupTransactions.length > 0">
        <div class="row mx-0  my-3 justify-content-center align-items-center" v-if="groupTransactionStatus !== 'ready'">
          <div class="col-auto ps-0">
            Loading Group Donations
          </div>
          <div class="col-auto p-0">
            <div class="spinner-border spinner-border-sm green-text" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <!-- TABLE HEADING -->
        <div class="row m-0">
          <div class="col-12 px-0 px-md-3">
            <!--DESKTOP-->
            <div class="row font13 green-text-dark d-none d-md-flex">
              <div class="col bold text-md-center px-0">
                <div class="lightgrey_bg w-100 h-100 p-3">
                  TRANSACTION REF
                </div>
              </div>
              <div class="col bold text-md-center px-1">
                <div class="lightgrey_bg w-100 h-100 p-3">
                  DATE
                </div>
              </div>
              <div class="col bold text-md-center px-0">
                <div class="lightgrey_bg w-100 h-100 p-3">
                  AMOUNT
                </div>
              </div>
              <div class="col bold text-md-center px-1">
                <div class="lightgrey_bg w-100 h-100 p-3">
                  PAYMENT TYPE
                </div>
              </div>
              <!-- <div class="col-md bold text-md-center px-0">
                <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                  INFO
                </div>
              </div> -->
            </div>
            <!--END DESKTOP-->
            <!--MOBILE-->
            <div class="row font13 green-text-dark d-md-none bold">
              <div class="col-3 text-center py-3 px-1">
                Receipt No.
              </div>
              <div class="col-3 text-center py-3 px-1">
                Date
              </div>
              <div class="col-3 text-center py-3 px-1">
                Amount
              </div>
              <div class="col-3 text-center py-3 px-1">
                Payment Type
              </div>
            </div>
            <!--END MOBILE-->
          </div>
        </div>
        <!-- END TABLE HEADING -->
        <div class="row m-0" v-if="groupTransactions.length < 1">
          <div class="col-12 p-3">
            There are no donations for the group {{group.donorGroupName}}.
          </div>
        </div>
        <div class="row mx-md-0" v-for="(donation, key) in groupTransactions.slice(start, finish)" :key="key">
          <div class="col-12 p-md-3 py-3 px-0" :class="{'lightgrey_bg': isOdd}">
            <!--DESKTOP-->
            <div class="row m-0 align-items-center position-relative">
              <div class="col p-0 text-center font15">
                {{donation.transactionReference}}
              </div>
              <div class="col p-0 text-center font15">
                {{date}}
              </div>
              <div class="col p-0 text-center font15 bold">
                {{ defaultCurrency }} {{ parseFloat(Math.round(donation.estimatedValue * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
              </div>
              <div class="col p-0 text-center bold font15">
                {{donation.paymentOption.method}}
              </div>
              <!-- <div class="col p-0 text-center">
                <div class="row align-items-center justify-content-end">
                  <div class="col-auto px-1">
                    <Button color="green" btnText="VIEW" size="small" @click="showDetails(key)"/>
                  </div>
                  <div class="col-auto px-1">
                    <Button color="gold" icon="arrow" size="xxsmall" v-if="item.is18a">
                      <Icon18a size="size16" />
                    </Button>
                    <Button color="darkgrey" icon="arrow" size="xxsmall" v-else>
                      <Icon18a size="size16" />
                    </Button>
                  </div>
                </div>
              </div> -->
            </div>
            <!--END DESKTOP-->
            <!--MOBILE-->
            <!-- <div class="row align-items-center d-md-none position-relative font13">
              <div class="col p-0 text-center">
                {{donation.transactionReference}}
              </div>
              <div class="col p-0 text-center">
                {{date}}
              </div>
              <div class="col p-0 text-center bold">
                {{ defaultCurrency }} {{ parseFloat(Math.round(item.estimatedValue * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
              </div>
              <div class="col-3 p-0 text-center">
                <Button color="green" btnText="VIEW" size="small" @click="view"/>
              </div>
            </div> -->
            <!--END MOBILE-->
          </div>
        </div>
        <!-- <transition name="slideup">
        <DonationPopup v-if="currentItem" :item="currentItem" :theKey="currentKey" :totalItems="filteredDonations.length" @close="closeDetails" @goForward="goForward(currentKey)" @goBack="goBack(currentKey)" />
        </transition> -->
        <!-- PAGINATION -->
        <div class="row mx-0 mt-5" v-if="totalPages > 1">
          <div class="col-12 text-center">
            <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
              {{ index }}
            </div>
          </div>
        </div>
        <!-- END PAGINATION -->
      </div>
      <div class="col-12 p-0" v-else>
        <div class="row mx-0  my-3 justify-content-center align-items-center" v-if="groupTransactionStatus !== 'ready'">
          <div class="col-auto ps-0">
            Loading Donations
          </div>
          <div class="col-auto p-0">
            <div class="spinner-border spinner-border-sm green-text" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div class="row mx-0 my-3 justify-content-center align-items-center" v-else>
          <div class="col-12 mb-3 text-center">
            This group doesn't have any past donations, try donating now.
          </div>
          <div class="col-12 text-center">
            <Button color="red" btnText="Donate Now" icon="arrow" @buttonClicked="goTo('donate')">
              <IconArrowForward color="white" size="size20" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Card: defineAsyncComponent(() => import('@/views/Card.vue')),
    IconGroups: defineAsyncComponent(() => import('@/components/icons/IconGroups.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'Group Transactions',
  mounted () {
    const id = parseInt(this.$route.params.id)
    this.fetchGroupTransactions(id)
  },
  data () {
    return {
      itemsPerPage: 10,
      currentPage: 0,
      currentItem: null,
      currentKey: null
    }
  },
  methods: {
    ...mapActions(['fetchGroupTransactions']),
    toGroups () {
      this.$router.push('/donorgroups')
    },
    goForward (val) {
      this.currentItem = this.filteredDonations[val + 1]
      this.currentKey = val + 1
    },
    goBack (val) {
      this.currentItem = this.filteredDonations[val - 1]
      this.currentKey = val - 1
    },
    closeDetails () {
      this.currentItem = null
      this.currentKey = null
    },
    showDetails (val) {
      this.currentKey = this.start + val
      this.currentItem = this.filteredDonations[this.currentKey]
      // this.currentItem = this.filteredDonations[val]
      // this.currentKey = val
    }
  },
  computed: {
    ...mapGetters(['groupTransactions', 'group', 'groupTransactionStatus']),
    totalPages () {
      return Math.ceil(this.groupTransactions.length / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    }
  }
}
</script>

<style scoped>
</style>
