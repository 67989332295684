<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 col-sm-10 col-md-9 col-lg-9 col-xl-7 p-0 my-4 card_item">
            <div class="row mx-0 h-100 justify-content-center above">
              <div class="col-12">
                <div class="row m-0 justify-content-center">
                  <div class="col-12 text-center p-0 my-4">
                    <div class="heading">
                      Organisation Details
                    </div>
                  </div>
                </div>
                <form @submit.prevent class="w-100 h-100">
                  <div class="row mx-0 h-100 justify-content-center">
                    <div class="col-12 p-0">
                      <div class="row m-0 justify-content-center">
                        <div class="col-12 p-0">
                          <div class="row m-0 justify-content-center">
                            <!-- organisation PICTURE -->
                            <div class="col-11 mb-5">
                              <div class="row justify-content-center align-items-center">
                                <div class="col-md-6 text-md-end mb-3">
                                  <div class="row m-0 justify-content-center justify-content-md-end">
                                    <div class="organisation_img p-0">
                                      <Image :imageName="newImage" width="137" v-if="newImage" />
                                      <Image :imageName="organisation.imageUrl" width="137" v-else-if="organisation.imageUrl" />
                                      <!-- <img :src="newImage" v-if="newImage">
                                      <img :src="url + '/static/uploads/images/' + organisation.imageUrl" v-else-if="organisation.imageUrl"> -->
                                      <IconUser size="sizefull" v-else />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 text-start">
                                  <label for="formFile" class="form-label medium">Change Organisation Image</label>
                                  <input class="gold-text bold w-100" type="file" id="formFile" @change="handleOrgImageUpload">
                                </div>
                              </div>
                            </div>
                            <!-- END organisation PICTURE -->
                            <!--PERSONAL DETAILS -->
                            <div class="col-md-11 mb-5">
                              <div class="row m-0">
                                <div class="col-12 mb-3 px-1">
                                  <TextField type="text" label="Organisation Name:" placeholder="Organisation Name" v-model="tempOrg.registeredName" id="registeredName" color="gold" />
                                </div>
                                <div class="col-12 mb-3 px-1">
                                  <SelectAll
                                    v-model="tempOrg.juristicType"
                                    id="juristicType"
                                    :options="juristicTypeOptions"
                                    placeholder="Select"
                                    displayValue="description"
                                    saveValue="saveValue"
                                    color="gold"
                                    width="full"
                                    label="Juristic Type:"
                                  />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Tax Number:" placeholder="Tax Number" v-model="tempOrg.taxNumber" id="taxNumber" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Registration Number:" placeholder="Registration Number" v-model="tempOrg.registrationNumber" id="registrationNumber" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="email" label="Email Address:" placeholder="email@mail.com" v-model="tempOrg.emailAddress" id="emailAddress" color="gold" autocomplete="username" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <PhoneFieldInt type="tel" label="Mobile No.:" placeholder="Mobile Number" v-model:modelValue="tempOrg.telephoneNumber" v-model:theError="isValidNumber" id="telephoneNumber" color="gold" ></PhoneFieldInt>
                                </div>
                                <!-- <div class="col-6 mb-3 px-1" v-if="tempOrg.alternateEmail || showAlternateEmail">
                                  <TextField type="email" label="Alternate Email Address:" placeholder="email@mail.com" v-model="tempOrg.alternateEmail" id="alternateEmail" color="gold" />
                                </div>
                                <div class="col-6 my-4 px-1" v-else>
                                  <Button color="gold" width="100" btnText="Add Secondary Email" icon="arrow" @buttonClicked="addSecondaryEmail">
                                    <IconPlus color="black" />
                                  </Button>
                                </div>
                                <div class="col-6 mb-3 px-1" v-if="tempOrg.alternateContactNo || showAlternateContactNo">
                                  <TextField type="tel" label="Alternate Contact No.:" placeholder="Alternate Contact Number" v-model="tempOrg.alternateContactNo" id="alternateContactNo" color="gold" />
                                </div>
                                <div class="col-6 my-4 px-1" v-else>
                                  <Button color="gold" width="100" btnText="Add Secondary Contact No." icon="arrow" @buttonClicked="addSecondaryContactNo">
                                    <IconPlus color="black" />
                                  </Button>
                                </div> -->
                              </div>
                            </div>
                            <!--END PERSONAL DETAILS -->
                            <!--ADDRESS DETAILS -->
                            <div class="col-md-11 mb-5">
                              <div class="row m-0">
                                <div class="col-12 mb-3 px-1">
                                  <MapAutocomplete
                                    @inputChanged="updateAddress"
                                    placeholder="Search Address"
                                    id="address"
                                    :value="tempOrg.addressList[0]"
                                    :map="true"
                                    color="outline-green"
                                  />
                                </div>
                                <div class="col-12 mb-3 px-1">
                                  <TextField type="text" label="Address Line 1:" placeholder="Address Line 1" v-model="tempOrg.addressList[0].addressLine1" id="addressLine1" color="gold" />
                                </div>
                                <div class="col-12 mb-3 px-1">
                                  <TextField type="text" label="Address Line 2:" placeholder="Address Line 2" v-model="tempOrg.addressList[0].addressLine2" id="addressLine2" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Town/City:" placeholder="Town/City" v-model="tempOrg.addressList[0].town" id="town" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Postal Code:" placeholder="Postal Code" v-model="tempOrg.addressList[0].postalCode" id="postalCode" color="gold" />
                                </div>
                              </div>
                            </div>
                            <!--END ADDRESS DETAILS -->
                            <!--SECONDARY ADDRESS DETAILS -->
                            <!-- <div class="col-md-11 mb-5" v-if="tempOrg.alternateAddress || showAlternateAddress">
                              <div class="row m-0">
                                <div class="col-12 mb-3 px-1">
                                  <MapAutocomplete
                                    @inputChanged="updateAlternateAddress"
                                    placeholder="Search Address"
                                    id="address"
                                    :value="tempOrg.secondaryAddress"
                                    :map="true"
                                    color="outline-green"
                                  />
                                </div>
                                <div class="col-12 mb-3 px-1">
                                  <TextField type="text" label="Secondary Address Line 1:" placeholder="Address Line 1" v-model="tempOrg.secondaryAddress1" id="secondaryaddressLine1" color="gold" />
                                </div>
                                <div class="col-12 mb-3 px-1">
                                  <TextField type="text" label="Secondary Address Line 2:" placeholder="Address Line 2" v-model="tempOrg.secondaryAddress2" id="secondaryaddressLine2" color="gold" />
                                </div>
                                <div class="col-6 mb-3 px-1">
                                  <TextField type="text" label="Secondary Town/City:" placeholder="Town/City" v-model="tempOrg.secondarytown" id="secondarytown" color="gold" />
                                </div>
                                <div class="col-6 mb-3 px-1">
                                  <TextField type="text" label="Secondary Province:" placeholder="Province" v-model="tempOrg.secondaryprovince" id="secondaryprovince" color="gold" />
                                </div>
                                <div class="col-6 mb-3 px-1">
                                  <TextField type="text" label="Secondary Postal Code:" placeholder="Postal Code" v-model="tempOrg.secondarypostalcode" id="secondarypostalCode" color="gold" />
                                </div>
                                <div class="col-6 mb-3 px-1">
                                  <TextField type="text" label="Secondary Country:" placeholder="Country" v-model="tempOrg.country.description" id="country" color="gold" />
                                </div>
                              </div>
                            </div>
                            <div class="col-6 my-4 px-1" v-else>
                              <Button color="gold" width="100" btnText="Add Secondary Address" icon="arrow" @buttonClicked="addSecondaryAddress">
                                <IconPlus color="black" />
                              </Button>
                            </div> -->
                            <!--END SECONDARY ADDRESS DETAILS -->
                            <!-- CONTACT DETAILS -->
                            <!-- <div class="col-md-11 mb-5">
                              <div class="row m-0">
                                <div class="col-6 mb-3 consent px-1">
                                  <div class="mb-3">I consent Al-Imdaad Foundation to contact me via:</div>
                                  <div class="form-check" v-for="item in correspondenceOptions" :key="item.id">
                                    <input class="form-check-input" type="checkbox" :value="item" :id="item.correspondanceID" v-model="tempOrg.correspondances">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      {{item.description}}
                                    </label>
                                  </div>
                                </div>
                                <div class="col-6 mb-3 consent px-1">
                                  I would like to receive updates Al-Imdaad Foundation:
                                  <div class="form-check mt-3">
                                    <input class="form-check-input" type="radio" :value="true" id="consentYes" name="consent" v-model="tempOrg.consent">
                                    <label class="form-check-label" for="consentYes">
                                      Yes
                                    </label>
                                  </div>
                                  <div class="form-check">
                                    <input class="form-check-input" type="radio" :value="false" id="consentNo" name="consent" v-model="tempOrg.consent">
                                    <label class="form-check-label" for="consentNo">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                            <!-- END CONTACT DETAILS -->
                            <!-- GIFTAID DETAILS -->
                            <!-- <div class="col-md-11 mb-5" v-if="isUK">
                              <div class="row m-0 justify-content-center">
                                <div class="col-5 col-lg-4 col-xl-3 mb-3 px-1">
                                  <img src="../assets/images/Gift-Aid-Logo-Green.png" class="img-fluid" />
                                </div>
                              </div>
                              <div class="row m-0 justify-content-center">
                                <div class="col-9 col-md-8 col-lg-6 col-xl-5 mb-3 giftAid_text px-1">
                                  Increase your donation by 25% at no extra cost!
                                </div>
                              </div>
                              <div class="row m-0 justify-content-center">
                                <div class="col-md-auto mb-3 consent">
                                  <div class="form-check mt-3 form-check-inline">
                                    <input class="form-check-input" type="radio" :value="true" id="giftAidYes" name="consent" v-model="tempOrg.giftAid">
                                    <label class="form-check-label" for="giftAidYes">
                                      Yes
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" :value="false" id="giftAidNo" name="consent" v-model="tempOrg.giftAid">
                                    <label class="form-check-label" for="giftAidNo">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="row m-0">
                                <div class="col-12 mb-3 consent px-1">
                                  I want to Gift Aid my donation and any donations I make in the future or have made in the past 4 years, to Al-Imdaad Foundation UK. I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all of my donations in that tax year it is my responsibility to pay any difference. Al-Imdaad Foundation UK will claim 25p on every £1 donated.
                                </div>
                              </div>
                              <div class="row m-0">
                                <div class="col-12 text-center px-1">
                                  <Button color="transparent" btnText=" Learn more about Gift Aid" />
                                </div>
                              </div>
                            </div> -->
                            <!-- END GIFTAID DETAILS -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-12 p-0 bg">
                <div class="row m-0 justify-content-center p-3">
                  <div class="col-6 px-1 col-md-auto px-md-3">
                    <Button color="red" width="100" btnText="Cancel" icon="arrow" @buttonClicked="cancel">
                      <ios-close-icon class="inline-icon"/>
                    </Button>
                  </div>
                  <div class="col-auto px-md-3 d-none d-md-block">
                    <Button color="gold" width="100" btnText="Save Changes" icon="arrow" @buttonClicked="saveOrganisation">
                      <IconArrowForward color="black" />
                    </Button>
                  </div>
                  <div class="col-6 px-1 d-md-none">
                    <Button color="gold" width="100" btnText="Save" icon="arrow" @buttonClicked="saveOrganisation">
                      <IconArrowForward color="black" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg_overlay"></div>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider class=" d-none d-md-block" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    MapAutocomplete: defineAsyncComponent(() => import('@/components/MapAutocomplete.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconUser: defineAsyncComponent(() => import('@/components/icons/IconUser.vue')),
    PhoneFieldInt: defineAsyncComponent(() => import('@/components/PhoneFieldInt.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Edit Organisation',
  data () {
    return {
      showAlternateEmail: false,
      showAlternateContactNo: false,
      showAlternateAddress: false,
      url: process.env.VUE_APP_URL_BASE,
      newImage: null,
      tempOrg: {
        imageUrl: '',
        registeredName: '',
        taxNumber: '',
        registrationNumber: '',
        emailAddress: '',
        telephoneNumber: '',
        faxNumber: '',
        juristicType: null,
        addressList: [
          {
            addressType: 'PHYSICAL_ADDRESS',
            addressLine1: '',
            addressLine2: '',
            town: '',
            postalCode: '',
            houseNumberName: '',
            // country: '',
            primaryAddress: true
          }
        ],
        contactPeople: [
          {
            firstName: '',
            lastName: '',
            contactDetails: {
              contactDetailsType: 'EMAIL',
              details: ''
            }
          }
        ],
        userID: null
      },
      juristicTypeOptions: [
        {
          description: 'None',
          saveValue: null
        },
        {
          description: 'Company',
          saveValue: 'COMPANY'
        },
        {
          description: 'Trust',
          saveValue: 'TRUST'
        }
      ],
      isValidNumber: true
    }
  },
  mounted () {
    this.tempOrg = this.organisation
  },
  computed: {
    ...mapGetters([
      'organisations',
      'user'
    ]),
    organisation () {
      const id = parseInt(this.$route.params.id)
      if (this.organisations.length > 0) {
        const filterArr = this.organisations.filter((organisation) => {
          return organisation.organisationID === id
        })
        if (filterArr.length > 0) {
          return filterArr[0]
        }
      }
      return {}
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    isValid () {
      if (this.tempOrg.telephoneNumber && !this.isValidNumber) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions([
      'updateOrganisation', 'saveOrgImage', 'saveOrgAddress', 'saveOrgContactPeople'
    ]),
    async handleOrgImageUpload (e) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
      const file = e.target.files[0]
      const base64 = await toBase64(file)
      this.newImage = base64
      this.tempOrg.imageUrl = base64.replace(/^[^,]+, */, '').replace(/\+/g, '%2B').replace(/\//g, '%2F').replace(/=/g, '%3D')
    },
    updateAddress (val) {
      this.tempOrg.addressList[0].addressLine1 = `${val.street_number} ${val.route}`
      this.tempOrg.addressList[0].addressLine2 = val.sublocality_level_1
      this.tempOrg.addressList[0].town = val.locality
      this.tempOrg.addressList[0].postalCode = val.postal_code
      this.tempOrg.addressList[0].houseNumberName = val.street_number
    },
    async saveOrganisation () {
      // const saveOrgContactPeople = {
      //   contactPeople: this.tempOrg.contactPeople,
      //   organisationID: this.tempOrg.organisationID
      // }
      // await this.saveOrgContactPeople(saveOrgContactPeople)

      const saveOrgAddress = {
        addressList: this.tempOrg.addressList,
        organisationID: this.tempOrg.organisationID
      }
      await this.saveOrgAddress(saveOrgAddress)
      if (this.newImage) {
        const saveImage = {
          organisationID: this.tempOrg.organisationID,
          image: this.tempOrg.imageUrl
        }
        await this.saveOrgImage(saveImage)
      }
      const saveOrgDetails = {
        registeredName: this.tempOrg.registeredName,
        taxNumber: this.tempOrg.taxNumber,
        registrationNumber: this.tempOrg.registrationNumber,
        emailAddress: this.tempOrg.emailAddress,
        telephoneNumber: this.tempOrg.telephoneNumber,
        faxNumber: this.tempOrg.faxNumber,
        organisationID: this.tempOrg.organisationID,
        juristicType: this.tempOrg.juristicType
      }
      await this.updateOrganisation(saveOrgDetails)
      window.scrollTo(0, 0)
      this.$router.push('/organisations')
      this.$emit('close')
      // actually submit form
    },
    cancel () {
      window.scrollTo(0, 0)
      this.$router.push('/organisations')
    },
    addSecondaryEmail () {
      this.tempOrg.alternateEmail = ''
      this.showAlternateEmail = true
    },
    addSecondaryContactNo () {
      this.tempOrg.alternateContactNo = ''
      this.showAlternateContactNo = true
    },
    addSecondaryAddress () {
      this.tempOrg.alternateAddress = ''
      this.showAlternateAddress = true
    }
  }
}
</script>

<style scoped>
.giftAid_text {
  font-family: "quicksand", Sans-serif;
  font-size: 1.7rem;
  color: var(--green-color-dark);
  line-height: 1.5;
  text-align: center;
}
.consent {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.form-check {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
}
.card_item {
  background-color: #fff;
  background-image: url(../../../../../assets/images/bg.png);
  background-position: center center;
  background-size: 32% auto;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
  position: relative;
}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 17px;
  font-weight: 600;
}
.above {
  color: var(--green-color-dark);
  position: relative;
  z-index: 1;
}
.bg_overlay {
  border-radius: 20px;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  background-image: linear-gradient(180deg, #FFFFFFF5 0%, #FFFFFF 59%);
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.bg {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.organisation_img {
  height: 137px;
  width: 137px;
  border-radius: 50%;
  position: relative;
  border: 6px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.organisation_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
@media (min-width: 992px) {
  .heading {
    color: var(--green-color-dark);
    font-family: "quicksand_bold", Sans-serif;
    font-size: 2.2rem;
    font-weight: 600;
  }
}
</style>
