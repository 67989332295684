<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 col-sm-10 col-md-9 col-lg-9 col-xl-7 p-0 my-4 card_item">
            <div class="row mx-0 h-100 justify-content-center above">
              <div class="col-12">
                <div class="row m-0 justify-content-center">
                  <div class="col-12 text-center p-0 my-4">
                    <div class="heading">
                      Donor Details
                    </div>
                  </div>
                </div>
                <form @submit.prevent class="w-100 h-100">
                  <div class="row mx-0 h-100 justify-content-center">
                    <div class="col-12 p-0">
                      <div class="row m-0 justify-content-center">
                        <div class="col-12 p-0">
                          <div class="row m-0 justify-content-center">
                            <!-- PROFILE PICTURE -->
                            <div class="col-11 mb-5">
                              <div class="row justify-content-center align-items-center">
                                <div class="col-md-6 text-md-end mb-3">
                                  <div class="row m-0 justify-content-center justify-content-md-end">
                                    <div class="profile_img p-0">
                                      <Image :imageName="newImage" width="137" v-if="newImage" />
                                      <Image :imageName="user.imageUrl" width="137" v-else-if="user.imageUrl" />
                                      <IconUser size="sizefull" v-else />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 text-start text-md-start">
                                  <label for="formFile" class="form-label medium">Change Profile Image</label>
                                  <input class="gold-text bold w-100" type="file" id="formFile" @change="handleOrgImageUpload">
                                </div>
                              </div>
                            </div>
                            <!-- END PROFILE PICTURE -->
                            <!--PERSONAL DETAILS -->
                            <div class="col-md-11 mb-5">
                              <div class="row m-0">
                                <div class="col-12 mb-3 px-1">
                                  <SelectAll v-if="isReady"
                                    v-model="profile.userTitleID"
                                    id="title"
                                    :options="titles"
                                    placeholder="Title"
                                    displayValue="userTitleDescription"
                                    saveValue="userTitleID"
                                    color="gold"
                                    :isSearch="true"
                                    label="Title:"
                                    width="full"
                                  />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="First Name:" placeholder="First Name" v-model="profile.firstName" id="firstName" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Last Name:" placeholder="Last Name" v-model="profile.lastName" id="lastName" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1" v-if="!isUK && (!profile.passportNumber || profile.passportNumber === '')" ref="theIDNumber">
                                  <TextField type="text" label="SA ID Number:" placeholder="ID Number" v-model="profile.idNumber" id="idNumber" color="gold" :errors="!idError ? [] : ['Error']" :hasErrors="!idError ? 0 : 1" />
                                </div>
                                <div class="col-md-6 mb-3 px-1" v-if="!isUK">
                                  <TextField type="text" label="Tax Number:" placeholder="Tax Number" v-model="profile.individualTaxNumber" id="individualTaxNumber" color="gold" />
                                </div>
                              </div>
                              <div class="row m-0" v-if="!isUK && (!profile.idNumber || profile.idNumber === '')">
                                <div class="col-md-6 mb-3 px-1" ref="thePassportNumber">
                                  <TextField type="text" label="Passport Number:" placeholder="Passport Number" v-model="profile.passportNumber" id="passportNumber" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <SelectAll
                                    v-model="passportCountryOfIssue"
                                    id="passportcountry"
                                    :options="countries"
                                    placeholder="Country of Issue"
                                    displayValue="description"
                                    color="gold"
                                    label="Country of Issue"
                                    :isSearch="true"
                                  />
                                </div>
                              </div>
                              <div class="row m-0">
                                <div class="col-md-6 mb-3 px-1" v-if="!isUK">
                                  <TextField type="email" label="Tax Certificate Email Address:" placeholder="Email Address" v-model="profile.taxCertificateEmailAddress" id="taxCertificateEmailAddress" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="email" label="Email Address:" placeholder="email@mail.com" v-model="profile.email" id="username" color="gold" autocomplete="username" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <PhoneFieldInt type="tel" :showError="!isValid" label="Mobile No.:" placeholder="Mobile Number" v-model:modelValue="profile.cell" v-model:theError="isValidNumber" id="cell" color="gold" ></PhoneFieldInt>
                                </div>
                              </div>
                              <div class="row m-0">
                                <div class="col-md-6 mb-3 px-1" v-if="profile.alternateEmail.contactDetailsID || showAlternateEmail">
                                  <TextField type="email" label="Alternate Email Address:" placeholder="email@mail.com" v-model="profile.alternateEmail.details" id="alternateEmail" color="gold" />
                                </div>
                                <div class="col-md-6 my-4 px-1" v-else>
                                  <Button color="gold" width="100" btnText="Add Secondary Email" icon="arrow" @buttonClicked="addSecondaryEmail">
                                    <IconPlus color="black" />
                                  </Button>
                                </div>
                                <div class="col-md-6 mb-3 px-1" v-if="profile.alternateContactNo.contactDetailsID || showAlternateContactNo">
                                  <PhoneFieldInt type="tel" label="Alternate Contact No.:" placeholder="Alternate Contact Number" v-model="profile.alternateContactNo.details" id="alternateContactNo" color="gold" ></PhoneFieldInt>
                                </div>
                                <div class="col-md-6 my-4 px-1" v-else>
                                  <Button color="gold" width="100" btnText="Add Secondary Contact No." icon="arrow" @buttonClicked="addSecondaryContactNo">
                                    <IconPlus color="black" />
                                  </Button>
                                </div>
                                <!-- <div class="col-6 mb-4">
                                  <TextField type="password" label="New Password:" placeholder="Password" v-model="profile.credentials.password" id="password" color="gold" autocomplete="new-password" />
                                </div>
                                <div class="col-6 mb-4">
                                  <TextField type="password" label="Confirm New Password:" placeholder="Confirm Password" v-model="profile.credentials.confirmPassword" id="confirmPassword" color="gold" autocomplete="confirm-password" :errors="['Passwords do not match']" :hasErrors="confirmErrors" />
                                </div> -->
                              </div>
                            </div>
                            <!--END PERSONAL DETAILS -->
                            <!--ADDRESS DETAILS -->
                            <div class="col-md-11 mb-5">
                              <div class="row m-0">
                                <div class="col-12 mb-3 px-1">
                                  <MapAutocomplete
                                    @inputChanged="updateAddress"
                                    placeholder="Search Address"
                                    id="address"
                                    v-model="profile.primaryAddress"
                                    :map="true"
                                    color="outline-green"
                                  />
                                </div>
                                <div class="col-12 mb-3 px-1">
                                  <TextField type="text" label="Address Line 1:" placeholder="Address Line 1" v-model="profile.postalAddress1" id="addressLine1" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Town/City:" placeholder="Town/City" v-model="profile.town" id="town" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Province:" placeholder="Province" v-model="profile.province" id="province" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Postal Code:" placeholder="Postal Code" v-model="profile.postalcode" id="postalCode" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <SelectAll
                                    v-model="profile.country"
                                    id="country"
                                    :options="countries"
                                    placeholder="Country"
                                    displayValue="description"
                                    color="gold"
                                    label="Country"
                                    :isSearch="true"
                                  />
                                  <!-- <TextField type="text" label="Country:" placeholder="Country" v-model="profile.country.description" id="country" color="gold" /> -->
                                </div>
                              </div>
                            </div>
                            <!--END ADDRESS DETAILS -->
                            <!--SECONDARY ADDRESS DETAILS -->
                            <div class="col-md-11 mb-5" v-if="profile.postalAddress2 || profile.alternateTown || profile.alternateProvince || profile.alternatePostalcode || showAlternateAddress">
                              <div class="row m-0">
                                <div class="col-12 mb-3 px-1">
                                  <TextField type="text" label="Secondary Address Line 1:" placeholder="Address Line 1" v-model="profile.postalAddress2" id="secondaryaddressLine1" color="gold" />
                                </div>
                                <div class="col-12 mb-3 px-1">
                                  <TextField type="text" label="Secondary Town/City:" placeholder="Town/City" v-model="profile.alternateTown" id="secondarytown" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Secondary Province:" placeholder="Province" v-model="profile.alternateProvince" id="secondaryprovince" color="gold" />
                                </div>
                                <div class="col-md-6 mb-3 px-1">
                                  <TextField type="text" label="Secondary Postal Code:" placeholder="Postal Code" v-model="profile.alternatePostalcode" id="secondarypostalCode" color="gold" />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 my-4 px-1" v-else>
                              <Button color="gold" width="100" btnText="Add Secondary Address" icon="arrow" @buttonClicked="addSecondaryAddress">
                                <IconPlus color="black" />
                              </Button>
                            </div>
                            <!--END SECONDARY ADDRESS DETAILS -->
                            <!-- CONTACT DETAILS -->
                            <div class="col-md-11 mb-5">
                              <div class="row m-0">
                                <div class="col-md-6 mb-3 consent px-1">
                                  <div class="mb-3">I consent to receiving transactional contact from Al-Imdaad Foundation via:</div>
                                  <div class="form-check" v-for="item in correspondenceOptions" :key="item.id">
                                    <input class="form-check-input" type="checkbox" :value="item" :id="item.correspondenceID" v-model="profile.correspondances">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      {{item.description}}
                                    </label>
                                  </div>
                                </div>
                                <div class="col-md-6 mb-3 consent px-1">
                                  <div class="mb-3">I consent to receiving updates from Al-Imdaad Foundation via:</div>
                                  <div class="form-check" v-for="item in marketingCorrespondenceOptions" :key="item.id">
                                    <input class="form-check-input" type="checkbox" :value="item" :id="item.correspondenceID" v-model="profile.correspondances">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      {{item.description.slice(9)}}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- END CONTACT DETAILS -->
                            <!-- GIFTAID DETAILS -->
                            <div class="col-md-11 mb-5" v-if="isUK">
                              <div class="row m-0 justify-content-center">
                                <div class="col-5 col-lg-4 col-xl-3 mb-3 px-1">
                                  <img src="@/assets/images/Gift-Aid-Logo-Green.png" class="img-fluid" />
                                </div>
                              </div>
                              <div class="row m-0 justify-content-center">
                                <div class="col-9 col-md-8 col-lg-6 col-xl-5 mb-3 giftAid_text px-1">
                                  Increase your donation by 25% at no extra cost!
                                </div>
                              </div>
                              <div class="row m-0 justify-content-center">
                                <div class="col-md-auto mb-3 consent">
                                  <div class="form-check mt-3 form-check-inline">
                                    <input class="form-check-input" type="radio" :value="true" id="giftAidYes" name="giftaid" v-model="profile.giftAid">
                                    <label class="form-check-label" for="giftAidYes">
                                      Yes
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" :value="false" id="giftAidNo" name="giftaid" v-model="profile.giftAid">
                                    <label class="form-check-label" for="giftAidNo">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="row m-0">
                                <div class="col-12 mb-3 consent px-1">
                                  I want to Gift Aid my donation and any donations I make in the future or have made in the past 4 years, to Al-Imdaad Foundation UK. I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all of my donations in that tax year it is my responsibility to pay any difference. Al-Imdaad Foundation UK will claim 25p on every £1 donated.
                                </div>
                              </div>
                              <div class="row m-0">
                                <div class="col-12 text-center px-1">
                                  <Button color="transparent" btnText=" Learn more about Gift Aid" />
                                </div>
                              </div>
                            </div>
                            <!-- END GIFTAID DETAILS -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-12 p-0 bg">
                <div class="row m-0 justify-content-center p-3">
                  <div class="col-6 col-md-auto px-1 px-md-3">
                    <Button color="red" width="100" btnText="Cancel" icon="arrow" @buttonClicked="cancel">
                      <ios-close-icon class="inline-icon" />
                    </Button>
                  </div>
                  <div class="col col-md-auto px-1 px-md-3 d-none d-md-flex">
                    <Button color="gold" width="100" btnText="Save Changes" icon="arrow" @buttonClicked="updateDetails" :isLoading="isLoading" :disabled="!isValid">
                      <IconArrowForward color="black" />
                    </Button>
                  </div>
                  <div class="col-6 col-md-auto px-1 px-md-3 d-md-none">
                    <Button color="gold" width="100" btnText="Save" icon="arrow" @buttonClicked="updateDetails" :isLoading="isLoading" :disabled="!isValid">
                      <IconArrowForward color="black" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg_overlay"></div>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider class="d-none d-md-block" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    MapAutocomplete: defineAsyncComponent(() => import('@/components/MapAutocomplete.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconUser: defineAsyncComponent(() => import('@/components/icons/IconUser.vue')),
    IconPlus: defineAsyncComponent(() => import('@/components/icons/IconPlus.vue')),
    PhoneFieldInt: defineAsyncComponent(() => import('@/components/PhoneFieldInt.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Edit Profile',
  data () {
    return {
      newPassword: '',
      confirmPassword: '',
      showAlternateEmail: false,
      showAlternateContactNo: false,
      showAlternateAddress: false,
      url: process.env.VUE_APP_URL_BASE,
      idError: false,
      profile: {
        primaryAddress: null,
        userID: '',
        cell: '',
        cellPrefix: 0,
        cellDetails: '',
        contactID: 0,
        donorID: '',
        email: '',
        faxNumber: '',
        firstName: '',
        individualTaxNumber: '',
        taxCertificateEmailAddress: '',
        lastName: '',
        organisation: '',
        organisationTaxNumber: '',
        passwordConfirm: '',
        postalAddress1: '',
        postalAddress2: '',
        postalcode: '',
        alternatePostalcode: '',
        telHome: '',
        telWork: '',
        town: '',
        alternateTown: '',
        userTitleID: null,
        volunteer: null,
        anonymous_receipts: null,
        hasMobileApp: null,
        profession: '',
        zakaatDateCalculation: '',
        zakaatCalculationDate: '',
        giftAid: null,
        houseNumberName: '',
        howCanYouHelp: '',
        receiveCommsViaEmail: null,
        receiveCommsViaSMS: null,
        receiveCommsViaPost: null,
        receiveMarketingCommsViaEmail: null,
        receiveMarketingCommsViaSMS: null,
        receiveMarketingCommsViaPost: null,
        optIntoMarketingComms: null,
        optIntoAnonymousReceipts: null,
        postalBoxNumber: '',
        privateBagNumber: '',
        physicalAddress: '',
        postalBoxNumber2: '',
        privateBagNumber2: '',
        country: {
          countryID: 0,
          description: '',
          isoCountryCode2: ''
        },
        correspondances: [],
        addressType: 'PHYSICAL_ADDRESS',
        alternateAddressType: 'PHYSICAL_ADDRESS',
        province: '',
        alternateProvince: '',
        organisationEntityWithTaxNumber: null,
        organisationEntity: null,
        permissionsListing: '',
        fullNameWithAIFId: '',
        cellFormattedForSending: '',
        individualEntityWithTaxNumber: null,
        id: {},
        roleListing: '',
        fullName: '',
        superUser: null,
        fullAddress: '',
        validCell: null,
        alternateEmail: {
          contactDetailsID: null,
          contactDetailsType: 'EMAIL',
          details: '',
          id: null
        },
        alternateContactNo: {
          contactDetailsID: null,
          contactDetailsType: 'PHONE',
          details: '',
          id: null
        },
        passportNumber: '',
        countryOfIssue: null
      },
      newImage: null,
      saveImage: null,
      isLoading: false,
      isValidNumber: true,
      passportCountryOfIssue: null,
      isReady: false
    }
  },
  async mounted () {
    this.isReady = false
    await this.fetchTitles()
    await this.fetchCorrespondence()
    await this.fetchMarketingCorrespondence()
    await Object.assign(this.profile, this.user)
    if (this.user.contactDetails && this.user.contactDetails.length > 0) {
      const email = this.user.contactDetails.find(el => el.contactDetailsType === 'EMAIL')
      if (email) {
        this.profile.alternateEmail = email
      }
      const phone = this.user.contactDetails.find(el => el.contactDetailsType === 'TELEPHONE_NUMBER')
      if (phone) {
        this.profile.alternateContactNo = phone
      }
    }
    if (this.profile.countryOfIssue) {
      const country = await this.getCountryByName(this.profile.countryOfIssue)
      this.passportCountryOfIssue = country
    }
    setTimeout(() => {
      this.isReady = true
    }, 200)
  },
  computed: {
    ...mapGetters([
      'titles',
      'countries',
      'correspondenceOptions',
      'marketingCorrespondenceOptions',
      'user'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    isValid () {
      if (this.profile.cell && !this.isValidNumber) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchTitles',
      'fetchCorrespondence',
      'fetchMarketingCorrespondence',
      'updateUserDetails',
      'updateUserAddress',
      'updateUserCredentials',
      'updateUserSecondaryAddress',
      'updateUserSecondaryEmail',
      'updateUserSecondaryPhone',
      'saveProfileImage',
      'verifyIDNumber'
    ]),
    getCountryByName (countryName) {
      console.log('getCountryByName', countryName)
      const theCountry = this.countries.filter(country => {
        if (country.description !== countryName) {
          return false
        }
        return true
      })
      console.log('theCountry', theCountry)
      return theCountry[0]
    },
    async handleOrgImageUpload (e) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
      const file = e.target.files[0]
      const base64 = await toBase64(file)
      this.newImage = base64
      this.saveImage = base64.replace(/^[^,]+, */, '').replace(/\+/g, '%2B').replace(/\//g, '%2F').replace(/=/g, '%3D')
    },
    updateAddress (val) {
      const country = this.countries.find(item => item.description === val.country)
      this.profile.postalAddress1 = `${val.street_number} ${val.route}`
      this.profile.town = val.locality
      this.profile.postalcode = val.postal_code
      this.profile.country = country
      this.profile.province = val.province
    },
    async updateDetails () {
      this.isLoading = true
      let idRet = true
      if (!this.isUK && this.profile.idNumber) {
        idRet = await this.verifyID()
      }
      if (idRet) {
        let address1 = null
        if (this.profile.postalAddress1) {
          address1 = this.profile.postalAddress1
        } else if (!this.profile.postalAddress1 && this.profile.primaryAddress) {
          address1 = this.profile.primaryAddress
        }
        const address = {
          email: this.profile.email,

          primaryAddress: {
            addressType: this.profile.addressType,
            addressLine1: address1,
            town: this.profile.town,
            postalCode: this.profile.postalcode,
            province: this.profile.province,
            country: this.profile.country,
            primaryAddress: true,
            houseNumberName: this.profile.houseNumberName,
            id: this.profile.id
          }
        }
        const details = {
          id: this.profile.id,
          email: this.profile.email,
          firstName: this.profile.firstName,
          lastName: this.profile.lastName,
          title: this.profile.userTitleID,
          zakaatCalculationDate: this.profile.zakaatCalculationDate,
          cellNumber: this.profile.cell,
          giftAid: this.profile.giftAid,
          individualTaxNumber: this.profile.individualTaxNumber,
          taxCertificateEmailAddress: this.profile.taxCertificateEmailAddress,
          idNumber: this.profile.idNumber,
          correspondence: this.profile.correspondances,
          passportNumber: this.profile.passportNumber,
          countryOfIssueID: ''
        }
        if (this.passportCountryOfIssue && this.passportCountryOfIssue.countryID) {
          details.countryOfIssueID = this.passportCountryOfIssue.countryID
        }
        if (details.idNumber && details.idNumber !== '') {
          details.passportNumber = ''
          details.countryOfIssueID = ''
        }
        if (!details.passportNumber || details.passportNumber === '') {
          details.countryOfIssueID = ''
        }
        const secondaryAddress = {
          addressLine2: this.profile.postalAddress2,
          town: this.profile.alternateTown,
          province: this.profile.alternateProvince,
          postalCode: this.profile.alternatePostalcode
        }
        const credentials = {
          id: this.profile.id,
          email: this.profile.email,
          donorID: this.profile.donorID,
          password: this.newPassword,
          confirmationPassword: this.confirmPassword
        }
        if (this.newImage) {
          const saveImage = this.saveImage
          await this.saveProfileImage(saveImage)
        }
        const alternateEmail = this.profile.alternateEmail
        const alternateContact = this.profile.alternateContactNo
        console.log('credentials', credentials)
        // this.updateUserCredentials(credentials)
        console.log('details', details)
        await this.updateUserDetails(details)
        await this.updateUserAddress(address)
        await this.updateUserSecondaryAddress(secondaryAddress)
        await this.updateUserSecondaryEmail(alternateEmail)
        await this.updateUserSecondaryPhone(alternateContact)

        this.isLoading = false
        window.scrollTo(0, 0)
        this.$router.push('/profile/0')
        this.$emit('close')
      }
      // actually submit form
    },
    cancel () {
      window.scrollTo(0, 0)
      this.$router.push('/profile/0')
    },
    addSecondaryEmail () {
      this.showAlternateEmail = true
    },
    addSecondaryContactNo () {
      this.showAlternateContactNo = true
    },
    addSecondaryAddress () {
      this.showAlternateAddress = true
    },
    async verifyID () {
      const ret = await this.verifyIDNumber(this.profile.idNumber)
      if (ret === true) {
        this.idError = false
      } else {
        this.idError = true
        var element = this.$refs.theIDNumber
        var top = element.offsetTop
        window.scrollTo(0, top)
        this.isLoading = false
      }
      return ret
    }
  }
}
</script>

<style scoped>
.giftAid_text {
  font-family: "quicksand", Sans-serif;
  font-size: 1.7rem;
  color: var(--green-color-dark);
  line-height: 1.5;
  text-align: center;
}
.consent {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.form-check {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
}
.card_item {
  background-color: #fff;
  background-image: url(../../../assets/images/bg.png);
  background-position: center center;
  background-size: 32% auto;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
  position: relative;
}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1rem;
  font-weight: 600;
}
.above {
  color: var(--green-color-dark);
  position: relative;
  z-index: 1;
}
.bg_overlay {
  border-radius: 20px;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  background-image: linear-gradient(180deg, #FFFFFFF5 0%, #FFFFFF 59%);
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.bg {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.profile_img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
@media (min-width: 992px) {
  .heading {
    font-size: 2.2rem;
  }
  .profile_img {
    height: 137px;
    width: 137px;
    border: 6px solid #fff;
  }
}
</style>
