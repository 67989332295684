<template>
  <div class="w-100" >
    <div class="row m-0 justify-content-center">
      <div class="col-12 p-0">
        <div class="container-lg" >
          <PageTitle :title="$route.name" />
        </div>
      </div>
      <div class="col-12 p-0 my-3 my-md-5">
        <div class="container-lg" >
          <div class="row mx-0 justify-content-center">
            <div class="col-9 col-md-12 text-center title white-text my-4" ref="donations">
              View donations for {{theOrganisation[0].registeredName}}
            </div>
            <div class="col-10 col-md-5 text-center green-text my-md-4">
              <Search type="text" color="outline" size="big" placeholder="search donations..." align="left" id="search" v-model="transactionSearch"/>
            </div>
          </div>
          <div class="w-100 my-3 my-md-5" ref="thedonations">
            <OrganisationPastDonations :transactionSearch="transactionSearch" @goToDonations="goToDonations" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    Search: defineAsyncComponent(() => import('@/components/Search.vue')),
    OrganisationPastDonations: defineAsyncComponent(() => import('./views/OrganisationPastDonations.vue'))
  },
  name: 'Organisation Donations',
  data () {
    return {
      transactionSearch: ''
    }
  },
  methods: {
    goToDonations () {
      var element = this.$refs.thedonations
      var top = element.offsetTop
      window.scrollTo(0, top)
    }
  },
  computed: {
    ...mapGetters(['user', 'organisations']),
    theOrganisation () {
      const id = parseInt(this.$route.params.id)
      return this.organisations.filter(organisation => {
        if (organisation.organisationID !== id) {
          return false
        }
        return true
      })
    }
  }
}
</script>

<style scoped>
.title {
  font-family: "quicksand_bold", Sans-serif;
  font-size: 26px;
  font-weight: 800;
}

@media (min-width: 992px) {
  .title {
    font-size: 43px;
  }
}
</style>
