<template>
  <div class="w-100" >
    <div class="container-lg">
      <!--TOP SECTION-->
      <div class="row m-0 justify-content-center">
        <div class="col-12 p-0">
          <PageTitle :title="$route.name" />
          <div class="row m-0 justify-content-center">
            <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
              View, manage and donate through your Donor Groups
            </div>
          </div>
          <div class="row m-0 justify-content-center">
            <div class="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-4 col-xxl-3 text-center p-0 my-4">
              <card icon="icon" @click="isNewGroupPopup = true" class="cursor">
                <IconGroups size="size35" />
                <template v-slot:heading>
                  New Donor Group
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>
      <!--END TOP SECTION-->
      <!--STATUS SPINNER-->
      <div class="row mx-0 my-3 justify-content-center align-items-center" v-if="groupsStatus !== 'ready'">
        <div class="col-auto ps-0 text-capitalize">
          {{groupsStatus}} Groups
        </div>
        <div class="col-auto p-0">
          <div class="spinner-border spinner-border-sm green-text" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <!--END STATUS SPINNER-->
      <!--MOBILE-->
      <div class="row mx-0 my-3 justify-content-center align-items-center d-md-none font15" v-if="groupsStatus === 'ready'">
        <div class="col-12 text-center medium green-text-dark">
          I would like to view groups:
        </div>
        <div class="col-auto text-center bold font13">
          <div class="tab lightgrey_bg green-text-dark text-center w-100">
            <div class="row m-0 justify-content-center align-items-center">
              <div class="col-auto" @click="groupType = 'managed'" :class="{active: groupType === 'managed'}">
                MANAGED BY ME
              </div>
              <div class="col-auto" @click="groupType = 'member'" :class="{active: groupType === 'member'}">
                I'M ADDED TO
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-0 my-3">
          <GroupsManage @goToDonations="goToDonations" v-if="groupType === 'managed'"/>
          <GroupsMember @goToDonations="goToDonations" v-else-if="groupType === 'member'" />
        </div>
      </div>
      <!--END MOBILE-->
      <!--DESKTOP-->
      <!--MANAGED GROUPS-->
      <div class="row mx-0 mb-4 justify-content-center d-none d-md-flex" v-if="myGroups && myGroups.length > 0">
        <div class="col-md-12 text-center title green-text my-4">
          Donor Groups Managed By Me
        </div>
        <div class="col-md-12">
          <GroupsManage @goToDonations="goToDonations" />
        </div>
      </div>
      <!--MANAGED GROUPS-->
      <!--MEMBER GROUPS-->
      <div class="row mx-0 justify-content-center d-none d-md-flex" v-if="otherGroups && otherGroups.length > 0">
        <div class="col-md-12 text-center title green-text my-4">
          Donor Groups I'm A Member Of
        </div>
        <div class="col-md-12">
          <GroupsMember @goToDonations="goToDonations" />
        </div>
      </div>
      <!--MEMBER GROUPS-->
      <!--END DESKTOP-->
    </div>
    <transition name="fade">
      <Popup @close="isNewGroupPopup = false" v-if="isNewGroupPopup">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            New Donor Group
          </div>
          <div class="col-12 text-center mb-3">
            Please enter a name for this group (at least 4 characters):
          </div>
          <div class="col-11 text-center mb-3">
            <TextField type="text" color="gold" placeholder="Group name here..." v-model="newGroup" id="name" />
          </div>
          <div class="col-11 text-center">
            <Button color="gold" btnText="Save Group" icon="arrow" @buttonClicked="saveGroup" :disabled="!newGroup || newGroup.length < 4" :isLoading="groupsStatus === 'adding'">
              <ios-checkmark-icon class="inline-icon" />
            </Button>
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    Card: defineAsyncComponent(() => import('../../components/AddNewCard.vue')),
    GroupsManage: defineAsyncComponent(() => import('@/views/GroupsManage.vue')),
    GroupsMember: defineAsyncComponent(() => import('@/views/GroupsMember.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconGroups: defineAsyncComponent(() => import('@/components/icons/IconGroups.vue')),
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue'))
  },
  name: 'DonorGroups',
  data () {
    return {
      isNewGroupPopup: false,
      newGroup: null,
      groupType: 'managed'
    }
  },
  methods: {
    ...mapActions(['createGroup']),
    async saveGroup () {
      const groupDetails = {
        founderUserID: this.user.userID,
        groupName: this.newGroup
      }
      await this.createGroup(groupDetails)
      this.isNewGroupPopup = false
    },
    goToDonations () {
      var element = this.$refs.thedonations
      var top = element.offsetTop
      window.scrollTo(0, top)
    },
    goTo (val) {
      if (val === 'donations') {
        var element = this.$refs.donations
        var top = element.offsetTop
        window.scrollTo(0, top)
      } else {
        this.$router.push(val)
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'jwt', 'groups', 'myGroups', 'otherGroups', 'groupsStatus'])
  }
}
</script>

<style scoped>
  .title {
    font-family: "quicksand_medium", Sans-serif;
    font-size: 26px;
    font-weight: 600;
  }
  .tab {
    border-radius: 50px;
    padding: 5px;
  }
  .tab .active {
    background-color: var(--green-color-dark);
    color: var(--gold-color);
    border-radius: 50px;
    padding: 5px 15px;
  }
</style>
