<template>
  <div class="w-100" >
    <div class="row m-0 justify-content-center">
      <div class="col-12 p-0">
        <div class="container-lg" >
          <PageTitle :title="$route.name" />
          <div class="row mx-0 justify-content-center">
            <div class="col-12 p-0">
              <div class="row m-0 justify-content-center">
                <div class="col-10 text-center p-0 mt-4 mb-3 page_heading">
                  Manage your Section 18A <span class="d-md-block">Tax Certificate</span>
                </div>
                <div class="col-12 text-center mb-3 p-0 gold_heading">
                  DONOR ID: {{user.donorID}}
                </div>
              </div>
              <div class="row m-0 justify-content-center">
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 my-4">
                  <Card icon="icon" :heading="currentYear" @click="download('currentYear')" class="cursor" :busy="downloadingCurrent">
                    <IconFile color="gold" size="size28" />
                  </Card>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0 justify-content-center">
            <div class="col-12 my-md-4 text-center mb-2">
              Other Download Options:
            </div>
            <div class="col-md-4 col-lg-3 my-md-4 mb-2">
              <Button color="green" :btnText="previousYear" @buttonClicked="download('previousYear')" width="100" icon="tes" :isLoading="downloadingPrevious">
                <IconFile color="gold" size="size16" />
              </Button>
            </div>
            <div class="col-md-4 col-lg-3 my-md-4 mb-2">
              <DateRangeSelect
                id="date"
                placeholder="DATE RANGE"
                @downloadRange="downloadRange"
                title="Date Range"
                :busy="downloadingRange"
              />
              <!-- <Button color="green" btnText="DATE RANGE" @buttonClicked="download(dateRange)" width="100" icon="tes">
                <IconCalendar color="gold" size="" />
              </Button> -->
            </div>
          </div>
          <div class="row m-0 justify-content-center">
            <div class="col-auto my-4 text-center d-none d-md-block">
              <Button color="green" btnText="ABOUT SECTION 18A" @buttonClicked="goTo" size="big" icon="tes">
                <ios-information-circle-outline-icon  class="inline-icon" />
              </Button>
            </div>
            <div class="col-12 mb-2 text-center d-md-none">
              <Button color="green" btnText="ABOUT SECTION 18A" @buttonClicked="goTo" icon="tes" width="100">
                <ios-information-circle-outline-icon  class="inline-icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 my-3 my-md-5">
        <div class="container-lg" >
          <div class="row mx-0 justify-content-center">
            <div class="col-8 col-md-12">
              <PageTitle title="ELIGIBLE DONATIONS" />
            </div>
          </div>
          <div class="w-100 my-3 my-md-5" ref="thedonations">
            <PastDonations @goToDonations="goToDonations" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    DateRangeSelect: defineAsyncComponent(() => import('@/components/datepicker/DateRangeSelect.vue')),
    Card: defineAsyncComponent(() => import('@/views/Card.vue')),
    PastDonations: defineAsyncComponent(() => import('./views/PastDonations18A.vue')),
    IconFile: defineAsyncComponent(() => import('@/components/icons/IconFile.vue')),
    'ios-information-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-information-circle-outline.vue'))
  },
  name: 'Manage18A',
  data () {
    return {
      downloadingCurrent: false,
      downloadingPrevious: false,
      downloadingRange: false
    }
  },
  methods: {
    ...mapActions(['downloadDatesSection18']),
    goToDonations () {
      var element = this.$refs.thedonations
      var top = element.offsetTop
      window.scrollTo(0, top)
    },
    async downloadRange (val) {
      console.log('download.', val)
      this.downloadingRange = true
      const details = {
        startDate: DateTime.fromMillis(val.startDate).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromMillis(val.endDate).toFormat('yyyy-MM-dd'),
        userID: this.user.userID
      }
      console.log('download.details', details)
      await this.downloadDatesSection18(details)
      this.downloadingRange = false
    },
    lastDayOfFinancialYearMonth () {
      const now = new Date()
      const year = now.getFullYear()
      const march = new Date(year, 2, 1)
      return new Date(march.setDate(0))
    },
    async download (val) {
      const dateRange = {
        startDate: null,
        endDate: null
      }
      if (val === 'currentYear') {
        this.downloadingCurrent = true
        let startDate = null
        let endDate = null
        const now = new Date()
        const year = now.getFullYear()
        startDate = new Date(year - 1, 2, 1).getTime()
        endDate = this.lastDayOfFinancialYearMonth().getTime()
        dateRange.startDate = startDate
        dateRange.endDate = endDate
      } else if (val === 'previousYear') {
        this.downloadingPrevious = true
        let startDate = null
        let endDate = null
        const now = new Date()
        const year = now.getFullYear()
        const lastYearMarch = new Date(year - 1, 2)
        debugger
        startDate = new Date(year - 2, 2, 1).getTime()
        endDate = new Date(lastYearMarch.setDate(0)).getTime()
        dateRange.startDate = DateTime.fromMillis(startDate).startOf('day').ts
        dateRange.endDate = DateTime.fromMillis(endDate).endOf('month').ts
      }
      await this.downloadRange(dateRange)
      this.downloadingCurrent = false
      this.downloadingPrevious = false
    },
    goTo () {
      window.scrollTo(0, 0)
      this.$router.push('/section18a')
    }
  },
  computed: {
    ...mapGetters(['user']),
    currentYear () {
      const d = new Date()
      const year = d.getFullYear()
      const lastYear = year - 1
      return 'Download Certificate for ' + lastYear + ' - ' + year
    },
    previousYear () {
      const d = new Date()
      const year = d.getFullYear() - 1
      const lastYear = year - 1
      return 'PREVIOUS YEAR[' + lastYear + ' - ' + year + ']'
    }
  }
}
</script>

<style scoped>
.profile_img {
  height: 103px;
  width: 103px;
  border-radius: 50%;
  position: relative;
  border: 4px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.gold_heading {
  color: var( --gold-color );
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 5px 5px 10px rgb(0 0 0 / 67%);
}
.top_text {
  color: #FFFFFF;
  padding: 12px 0px 0px 0px;
  text-shadow: 5px 5px 10px rgb(0 0 0 / 67%);
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1rem;
  font-weight: 500;
}
.tabs {
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.tabs.active {
  border-bottom: 2px solid var(--gold-color);
}
.tab_heading {
  color: var( --green-color );
  font-family: "quicksand", Sans-serif;
  font-size: 1.55rem;
  font-weight: 100;
  line-height: 1.3em;
}
.title {
  font-family: "quicksand_bold", Sans-serif;
  font-size: 26px;
  font-weight: 800;
}

@media (min-width: 992px) {
  .gold_heading {
    font-size: 1.4rem;
  }
  .top_text {
    font-size: 1.2rem;
  }
  .tab_heading {
    font-size: 2.55rem;
    line-height: 1.3em;
  }
  .profile_img {
    height: 188px;
    width: 188px;
  }
  .title {
    font-size: 43px;
  }
}
</style>
