<template>
  <div class="w-100" >
    <div class="container-lg">
      <div class="row m-0 justify-content-center">
        <div class="col-12 p-0">
          <PageTitle :title="$route.name" />
          <div class="row m-0 justify-content-center">
            <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
              View, manage and donate through your Organisations
            </div>
          </div>
          <div class="row m-0 justify-content-center">
            <div class="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-4 col-xxl-3 text-center p-0 my-4">
              <card icon="icon" @click="goTo('organisationnew')" class="cursor">
                <IconOrganisations size="size35" />
                <template v-slot:heading>
                  New Organisation
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 mb-4 justify-content-center">
        <div class="col-md-12">
          <OrganisationsTable />
        </div>
      </div>
    </div>
    <LatestAppealsWithSlider class="d-none d-md-block"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue')),
    Card: defineAsyncComponent(() => import('../../components/AddNewCard.vue')),
    OrganisationsTable: defineAsyncComponent(() => import('./views/OrganisationsTable.vue')),
    IconOrganisations: defineAsyncComponent(() => import('@/components/icons/IconOrganisations.vue'))
  },
  name: 'Organisations',
  data () {
    return {
      isNewGroupPopup: false,
      newGroup: null
    }
  },
  beforeMount () {
    if (!this.organisations) {
      this.fetchOrganisations()
    }
  },
  methods: {
    ...mapActions(['fetchOrganisations']),
    goTo (val) {
      this.$router.push(val)
    }
  },
  computed: {
    ...mapGetters(['user', 'jwt', 'organisations'])
  }
}
</script>

<style scoped>
  .title {
    font-family: "quicksand_medium", Sans-serif;
    font-size: 26px;
    font-weight: 600;
  }
</style>
