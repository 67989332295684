<template>
  <div class="w-100" >
    <div class="container-lg">
      <div class="row m-0 justify-content-center">
        <div class="col-12 p-0">
          <PageTitle :title="$route.name" />
          <div class="row m-0 justify-content-center">
            <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
              View, manage and donate your Zakaah Calculations
            </div>
          </div>
          <div class="row m-0 justify-content-center">
            <div class="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-4 col-xxl-3 text-center p-0 my-4">
              <card icon="icon" @click="goToZakaah" class="cursor">
                <IconCalculator size="size35" />
                <template v-slot:heading>
                  Open Zakaah Calculator
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 my-3 justify-content-center align-items-center" v-if="zakaatStatus !== 'ready'">
        <div class="col-auto ps-0 text-capitalize">
          {{zakaatStatus}} Zakaah
        </div>
        <div class="col-auto p-0">
          <div class="spinner-border spinner-border-sm green-text" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <!--DESKTOP-->
      <div class="row mx-0 mb-4 justify-content-center">
        <div class="col-md-12">
          <ManageZakaahTable />
        </div>
      </div>
      <!--END DESKTOP-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    Card: defineAsyncComponent(() => import('../../components/AddNewCard.vue')),
    ManageZakaahTable: defineAsyncComponent(() => import('./views/ManageZakaahTable.vue')),
    IconCalculator: defineAsyncComponent(() => import('@/components/icons/IconCalculator.vue'))
  },
  name: 'ManageZakaah',
  data () {
    return {
      isNewGroupPopup: false
    }
  },
  mounted () {
    if (this.user) {
      this.fetchPastZakaat()
    }
  },
  methods: {
    ...mapActions(['fetchPastZakaat']),
    goToZakaah () {
      window.scrollTo(0, top)
      this.$router.push('zakaahcalculator/new')
    }
  },
  computed: {
    ...mapGetters(['user', 'pastZakaat', 'zakaatStatus'])
  }
}
</script>

<style scoped>
</style>
